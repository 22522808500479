.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 32px;
  margin-left: 10vw;
  margin-right: 10vw;

  height: 10vh;

  /* Inside auto layout */

  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}
.holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80vw;
 
}
.hamburger {
  display: none;
}
/* .logo {
  font-family: "Epilogue";
  font-style: normal;
  font-weight: 600;
  font-size: 51.7778px;
  line-height: 53px;
  color: white;
  font-size: 40px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
} */
.nav-list {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 32px;
  list-style: none;

  width: 368px;
  height: 40px;

  background: #ffffff;

  font-family: "Epilogue";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #2d2c2c;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}

.contact-button {
  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  gap: 8px;


  width: 111px;
  height: 40px;

  background: #2d2c2c;

  font-family: "Epilogue";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 24px */

  /* White */

  color: #ffffff !important;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}
.contact-button a {
  color: #ffffff;
}
.nav-menu active {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 32px;
  list-style: none;

  width: 368px;
  height: 40px;

  background: #ffffff;

  font-family: "Epilogue";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #2d2c2c;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
a, a:visited, a:hover, a:active {
  color: inherit;
}
@media screen and (min-width: 768px) {
ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 32px;
  list-style: none;

  width: 368px;
  height: 40px;
  

  background: #ffffff;

  font-family: "Epilogue";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  color: #2d2c2c;

  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
}

@media screen and (max-width: 768px) {
  .navbar {
    margin-left: 5vw;
    margin-right: 5vw;
    height: 10vh;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }
  .hamburger {
    display: flex;
    position: absolute;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    right: 5vw;
  }
  .nav-list {
    display: none;
  }
  .nav-menu active {
 

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: none;

    
  }
 ul {
 position: absolute;
 top: 10vh;
left:30vw;
background-color: white;
list-style: none;
padding: 0px;

 }
  li {
    padding: 10px;
    border-bottom: 1px solid #ccc;

  }
  .contact-button {
   
  
    background: #ffffff;
  
  
    color: #2d2c2c;
    
  
    /* Inside auto layout */
  
    flex: none;
    order: 1;
    flex-grow: 0;
  }

}




