.popup {
  display: none;
  font-size: 10px;
  position: fixed;

  bottom: 30vh;
left: 40vw;
  /* top: 10rem; */
  /* height: 60vh; */
  /* height: 20rem; */
  width: 25%;
  /* display: flex; */
  flex-direction: column;
  /* justify-content: space-between; */
  padding: 0.75rem;
  /* box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4); */
  border-radius: 10px;
  z-index: 1000;
}
.popup.show {
  display: flex;
}

.chat-btn-active {
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.chat-btn {
  color: #ffffff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0;
  font-family: "Epilogue";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
