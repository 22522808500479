.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
    width: 98vw;
    height: 100vh;
    overflow: hidden;

}
.card {
  height: 90%;
  display: flex;
  position: relative;
  left: 0;
  transition: all 1s ease-out;
}

.card-container {
  width: 100vw;
    height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.item {
  width: 60%;
  height: 90%;
  background-color: #d9d9d9;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  transition: all 1s ease;
}
.item:hover {
  transform: scale(1.04);
}
.image-container {
  flex: 9;
 

    
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.image-container img {
  width: 100%;
  height: 80%;
  object-fit: cover;
}
.text-container {
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  font-family: "Epilogue";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 117.5%;
  /* or 23px */

  display: flex;
  align-items: center;

  color: #2d2c2c;
}

.arrow-left {
    height: 50px;
    position: absolute;
    top: 50%;
    left: 100px;
    color: #2d2c2c;
  }
  .arrow-right {
      height: 50px;
      position: absolute;
      top: 50%;
      right: 100px;
      color: #2d2c2c;
    }
    .arrow-left:hover {
        transform: scale(1.1);
        cursor: pointer;
    }
    .arrow-right:hover {
        transform: scale(1.1);
        cursor: pointer;
    }
    @media screen and (max-width: 768px) {
      .profile {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
          width: 98vw;
          height: 100vh;
          overflow: hidden;
      
      }
   
      .item {
        width: 90%;
        height: 70%;
      }
     
      .image-container img {
        height: 25vh;
        width: 85vw;
      }
      .text-container {
        font-size: 15px;
      }
      .arrow-left {
        height: 30px;
        left: 50px;
        top: 20vh;
      }
      .arrow-right {
        height: 30px;
        right: 50px;
        top: 20vh;
      }

    }