.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90vh;
}
.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48vw;
  height: 90vh;
}
/* @media screen and (max-width: 768px) {
  .right {
    width: 100%;
    height: 90vh;
  }
} */

.right h1 {
  font-family: "Epilogue";
  font-style: normal;
  font-weight: 800;
  font-size: 15vh;
  margin-top: -5vh;

  /* or 176px */

  color: #2d2c2c;
}
/* @media screen and (max-width: 768px) {
  .right h1 {
   font-size: 75px;
  }
} */
.FSD {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 13.5039px 45.013px;
  gap: 7.5px;
  font-family: "Epilogue";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  border: 1px solid #2d2c2c;
  border-radius: 75.0217px;
}
.PM {
  font-family: "Epilogue";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 117.5%;
  color: #2d2c2c;
  padding: 2vh;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 48vw;
  height: 90vh;
}
/* .imageContainer {
  width: 50%;
  height: 50vh;
  left: 10px;
} */
.imageContainer img {

  height: 70vh;
  left: 10px;
  
}

@media screen and (max-width: 768px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90vh;
 
  }
  .right {
    width: 100%;
    height: 50vh;
  }
  .right h1 {
    font-size: 75px;
  }
  .left {
    width: 100%;
    height: 50vh;
    margin-top: -20vh;
  }
  .imageContainer {
    width: 100%;
    height: 50vw;
  }
  .imageContainer img {
    width: 90vw;
    height: 35vh;
    
  }
  .FSD {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 13.5039px 45.013px;
    gap: 7.5px;
    font-size: 15px;
  }
  .PM {
    font-size: 15px;
  }
} ;
